import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getVendorProfileApi } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import EditVendorModal from "../modal/EditVendorModal";
import primiumIcon from "../../images/crown.png";

export default function VendorProfileDetails() {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [vendorData, setVendorData] = useState();
  const [showEditVendorModal, setShowEditVendorModal] = useState(false);

  async function handleVendorProfileData() {
    setLoader(true);
    try {
      const response = await getVendorProfileApi();
      setVendorData(response?.data?.data);
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleVendorProfileData();
  }, []);

  return (
    <div>
      <div>
        <h3 className="pt-3 pb-2">{t("vendor_profile.title")}</h3>
      </div>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <Card style={{ width: "70%" }}>
          <Card.Header>
            <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
              <div className="text-left" style={{ flex: "1" }}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-baseline">
                    <h3 className="text-color mr-2">{vendorData?.name}</h3>
                    {vendorData?.isPremium && (
                      <img className="" src={primiumIcon} alt="" height={30} />
                    )}
                  </div>
                  <FontAwesomeIcon
                    className="edit-icon"
                    icon={faPenToSquare}
                    onClick={() => setShowEditVendorModal(true)}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">{t("vendor_profile.created_at")}</h5>
                  <p className="m-0">
                    {moment(vendorData?.createdAt).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">{t("vendor_profile.commission")}</h5>
                  <p className="m-0">{vendorData?.commission}%</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 style={{ margin: "0" }}>{t("vendor_profile.email")}</h5>
                  <p style={{ margin: "0" }}>{vendorData?.email}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 style={{ margin: "0" }}>{t("vendor_profile.phone")}</h5>
                  {vendorData?.phoneNumber ? (
                    <p style={{ margin: "0" }}>+357{vendorData?.phoneNumber}</p>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
          </Card.Header>
        </Card>
      )}
      {showEditVendorModal && (
        <EditVendorModal
          show={showEditVendorModal}
          onHide={() => setShowEditVendorModal(false)}
          vendorData={vendorData}
          refresh={handleVendorProfileData}
        />
      )}
    </div>
  );
}
