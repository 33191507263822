import React from "react";
import { useTranslation } from "react-i18next";

export default function Filter({
  type,
  value,
  setValue,
  options,
  title,
  setFilterType,
}) {
  const { t } = useTranslation();

  return (
    <div>
      {type === "miniTab" && (
        <div className="mini-tabs">
          {options?.map((option) => (
            <span
              className={`${option.value === value ? "active-tab" : "tab"}`}
              onClick={() => setValue(option.value)}
            >
              {option?.label}
            </span>
          ))}
        </div>
      )}
      {type === "filter" && (
        <div>
          <select
            style={{ textAlign: "start", fontWeight: "bold" }}
            class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
            onChange={(e) => setFilterType(e.target.value)}
            // value={limitValue}
          >
            <option value={""} disabled>
              {t("common.select")}
            </option>
            <option value="all">{t("create_edit_tier_form.label.all")}</option>
            <option value="silver">
              {t("create_edit_tier_form.label.silver")}
            </option>
            <option value="gold">
              {" "}
              {t("create_edit_tier_form.label.gold")}
            </option>
            <option value="platinum">
              {" "}
              {t("create_edit_tier_form.label.platinum")}
            </option>
            <option value="diamond">
              {" "}
              {t("create_edit_tier_form.label.diamond")}
            </option>
          </select>
        </div>
      )}
    </div>
  );
}
