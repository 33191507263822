import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { addSubVendorApi } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function AddSubVendorModal({ show, onHide, refresh }) {
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { t } = useTranslation();

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (name.trim() === "") {
      setNameError(`${t("errors.name_error")}`);
      return;
    }
    if (email.trim() === "") {
      setEmailError(`${t("errors.email_error")}`);
      return;
    } else if (!validateEmail(email)) {
      setEmailError(`${t("errors.valid_email_error")}`);
      return;
    }
    if (password.trim() === "") {
      setPasswordError(`${t("errors.password_error")}`);
      return;
    } else if (password?.length < 6) {
      setPasswordError(`${t("errors.password_limit_error")}`);
      return;
    }

    setLoader(true);
    try {
      const postData = {
        email,
        password,
        name,
      };
      const response = await addSubVendorApi(postData);
      toast.success(`${t("toast_messages.employee_add_success_message")}`);
      onHide();
      refresh();
    } catch (e) {
      toast.error(
        e?.response?.data?.data
          ? e?.response?.data?.data
          : e?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("add_employee_form.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder={t("add_employee_form.placeholder_one")}
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            />
            {nameError && <span className="text-danger">{nameError}</span>}
          </div>
          <div className="mb-3">
            <input
              type="email"
              placeholder={t("add_employee_form.placeholder_second")}
              className="form-control"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
            />
            {emailError && <span className="text-danger">{emailError}</span>}
          </div>
          <div className="mb-3 password-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder={t("add_employee_form.placeholder_third")}
              className="form-control"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
              }}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="password-icon"
              onClick={handleTogglePassword}
            />
          </div>
          {passwordError && (
            <span className="text-danger">{passwordError}</span>
          )}

          <Button variant="primary" type="submit" className="w-100">
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              `${t("add_employee_form.title")}`
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
