import React, { useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { uploadDocuments } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ShowImage from "../modal/ShowImage";
import { requestReviewApi } from "../../services/User/UserService";

export default function Documents({
  show,
  onHide,
  refresh,
  selectedDocuments,
  edit,
}) {
  console.log(selectedDocuments);
  const [showImage, setShowImage] = useState(false);
  const [imageKeys, setImageKeys] = useState({
    proofOfOwnerShip: "",
  });
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [industry, setIndustry] = useState("");
  const [industries, setIndustries] = useState([]);

  // useEffect(() => {
  //   const fetchIndustries = async () => {
  //     try {
  //       const response = await getIndustryApi();
  //       setIndustries(response.data || []);
  //     } catch (err) {
  //       toast.error(t("error.fetchIndustries"));
  //     }
  //   };

  //   fetchIndustries();
  // }, []);

  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }

  async function onSignUp(e) {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("proofOfOwnerShip", imageKeys.proofOfOwnerShip);
    formData.append("industry", industry);

    try {
      if (edit === "edit") {
        await requestReviewApi();
      }
      await uploadDocuments(formData);
      toast.success(`${t("toast_messages.document_success_message")}`);
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <div className="doc-form">
          <form onSubmit={onSignUp}>
            <h3 className="text-center p-2">
              {t("upload_document_form.title")}
            </h3>

            <div className="form-group">
              <label>
                {t("upload_document_form.thread_label")}{" "}
                <span className="text-danger">*</span>
              </label>

              <input
                id="files"
                type="file"
                className="form-control"
                required
                accept=".jpg,.png,.jpeg,.pdf"
                onChange={(e) =>
                  setImageKeys({
                    ...imageKeys,
                    proofOfOwnerShip: e.target.files[0],
                  })
                }
              />
              {selectedDocuments?.proofOfOwnerShip && (
                <>
                  {selectedDocuments.proofOfOwnerShip.endsWith(".pdf") ? (
                    <a
                      href={`${process.env.REACT_APP_API_BASE_URL}${selectedDocuments.proofOfOwnerShip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary cursor-pointer underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `${process.env.REACT_APP_API_BASE_URL}${selectedDocuments.proofOfOwnerShip}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {selectedDocuments.proofOfOwnerShip.length > 30
                        ? selectedDocuments.proofOfOwnerShip.slice(0, 29) +
                          "..."
                        : selectedDocuments.proofOfOwnerShip}
                    </a>
                  ) : (
                    <span
                      className="text-primary cursor-pointer underline"
                      onClick={() =>
                        prevImage(selectedDocuments.proofOfOwnerShip)
                      }
                    >
                      {selectedDocuments.proofOfOwnerShip.length > 30
                        ? selectedDocuments.proofOfOwnerShip.slice(0, 29) +
                          "..."
                        : selectedDocuments.proofOfOwnerShip}
                    </span>
                  )}
                </>
              )}
            </div>
            {/* <Form.Group controlId="formIndustry">
              <Form.Label>{t("profile.industryLabel")}</Form.Label>
              <Form.Control
                as="select"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                required
              >
                <option value="" disabled>
                  {t("profile.industryPlaceholder")}
                </option>
                {industries.map((ind) => (
                  <option key={ind.id} value={ind.id}>
                    {ind.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
            <div className="text-center d-flex justify-content-center ">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                style={{ width: "98%" }}
                disabled={loader}
              >
                {loader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  `${t("upload_document_form.button_title")}`
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showImage && (
        <ShowImage
          show={showImage}
          onHide={() => setShowImage(false)}
          image={selectedImage}
        />
      )}
    </>
  );
}
