import React, { useEffect, useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/Logo/mainLogoText.svg";
import logoText from "../../../images/Logo/mainLogoText.svg";
import { getVendorProfileApi } from "../../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { verifyAccess } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [access, setAccess] = useState(null);
  const dispatch = useDispatch();

  async function handleVendorProfileData() {
    setLoader(true);
    try {
      const response = await getVendorProfileApi();
      setVendorData(response?.data?.data);
      localStorage.setItem("verifyStatus", response?.data?.data?.verifyStatus);
      localStorage.setItem("isPremium", response?.data?.data?.isPremium);
      dispatch(verifyAccess(response?.data?.data?.verifyStatus));
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  const fetchData = async () => {
    try {
      const permissions = localStorage.getItem("role");
      if (permissions) {
        setAccess(JSON.parse(permissions));
      } else {
        console.error("Permissions data not found in local storage");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  useEffect(() => {
    handleVendorProfileData();
    fetchData();
  }, []);

  return (
    <div className="nav-header" style={{ zIndex: "12" }}>
      <Link to={access === "Vendor" ? "/" : "#"} className="brand-logo">
        <img className="logo-abbr" src={logo} alt="" />
        <img className="logo-compact" src={logoText} alt="" />
        <img className="brand-title" src={logoText} alt="" />
      </Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
