import React, { useEffect, useState } from "react";
import CutleryIcon from "../../images/shoppingBag.png";
import moment from "moment";
import AddOffers from "../modal/AddOffers";
import toast from "react-hot-toast";
import {
  getOfferApi,
  addOfferApi,
  editOfferApi,
  deleteOfferApi,
} from "../../services/Store/StoreServices";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import OffersPageSkeleton from "../components/SkeletonPages/OffersPageSkeleton";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../modal/ConfirmationModal";

function LoyaltyComponent(props) {
  const programId = props?.location?.state?.id;
  const [addOfferModal, setAddOfferModal] = useState(false);
  const [editOfferModal, setEditOfferModal] = useState(false);
  const [offerItem, setOfferItem] = useState(null);
  const [offersList, setOfferList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("create_offer.unlimited_explanation")}
    </Tooltip>
  );

  async function getOffersListing() {
    setLoader(true);
    try {
      const response = await getOfferApi(programId);
      setOfferList(response?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    getOffersListing();
  }, [programId]);

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <h3></h3>
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setAddOfferModal(true)}
          >
            {t("common.add_offer")}
          </button>
        </div>
      </div>
      {loader ? (
        <OffersPageSkeleton count={2} />
      ) : (
        <>
          {offersList?.map((item) => (
            <>
              <div
                className="loyaltyItem mt-3"
                key={item._id}
                style={{
                  backgroundColor: item?.pointNeeded === "Debit" && "#fee6ea",
                }}
              >
                <div className="d-flex justify-content-between">
                  <h2 className="headerText m-0">{item?.title}</h2>
                  <Dropdown>
                    <Dropdown.Toggle className="offer-action-btn">
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="offer-acton-options">
                        <div
                          class="btn btn-primary shadow btn-xs sharp list-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditOfferModal(true);
                            setOfferItem(item);
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />{" "}
                        </div>
                        <div
                          class="btn btn-danger  btn-xs sharp list-btn"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "red",
                            color: "white",
                          }}
                          onClick={() => {
                            setDeleteModal(true);
                            setOfferItem(item);
                          }}
                        >
                          <i class="fa fa-trash"></i>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="subheaderText text-left pb-0">
                    {t("offer_card.h1")}:{" "}
                    {item?.oncePerUser > 1000000
                      ? t("create_offer.unlimited")
                      : item?.oncePerUser}
                    {item?.oncePerUser > 1000000 && (
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <i
                          className="fa-sharp fa-solid fa-circle-info p-1"
                          style={{ fontSize: "20px", color: "#042e77" }}
                        ></i>
                        {/* <Button variant="link" className="p-0 font-w600"></Button> */}
                      </OverlayTrigger>
                    )}
                  </p>
                  <p className="subheaderText text-left m-0">{item?.tier}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="subheaderText text-left m-0">
                    {t("offer_card.h2")} :{" "}
                    {moment(item?.startDate).format("ll")}
                  </p>
                  <p className="subheaderText text-left m-0">
                    {t("offer_card.h3")}: {item?.pointPrice}
                  </p>
                </div>
                <div
                  className="darkView"
                  style={{
                    backgroundColor:
                      item?.pointNeeded === "Debit" && "rgb(252 85 84)",
                  }}
                >
                  <div style={{ justifyContent: "space-between" }}>
                    <p className="tillText text-left">{t("offer_card.h4")}</p>
                    <p className="validText">
                      {moment(item?.validDate).format("ll")}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 44,
                      height: 44,
                      backgroundColor: "white",
                      borderRadius: 22,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="cutleryImage"
                      src={CutleryIcon}
                      alt="cutlery"
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
      )}
      {!offersList.length > 0 && !loader && (
        <div className="d-flex justify-content-center">
          <h3>{t("common.empty_table")}</h3>
        </div>
      )}
      {addOfferModal && (
        <AddOffers
          title={t("common.add_offer")}
          successMessage={t("toast_messages.add_offer_success_message")}
          show={addOfferModal}
          onHide={() => setAddOfferModal(false)}
          api={addOfferApi}
          refresh={getOffersListing}
          programId={programId}
        />
      )}
      {editOfferModal && (
        <AddOffers
          title={t("common.edit_offer")}
          successMessage={t("toast_messages.edit_offer_success_message")}
          show={editOfferModal}
          onHide={() => setEditOfferModal(false)}
          api={editOfferApi}
          refresh={getOffersListing}
          programId={programId}
          item={offerItem}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          data={offerItem}
          api={deleteOfferApi}
          successMessage={"Offer Deleted Successfully"}
          refresh={getOffersListing}
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          content={"Are you sure you want to delete this offer?"}
        />
      )}
    </>
  );
}

export default LoyaltyComponent;
