import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { editVendor } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const EditVendorModal = ({ show, onHide, vendorData, refresh }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(vendorData?.name);
  const [number, setNumber] = useState(vendorData?.phoneNumber);
  const [countryCode, setCountryCode] = useState(vendorData?.countryCode);
  const [industry, setIndustry] = useState(vendorData?.industry || "");
  const [industries, setIndustries] = useState([]);
  const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   const fetchIndustries = async () => {
  //     try {
  //       const response = await getIndustryApi();
  //       setIndustries(response.data || []);
  //     } catch (err) {
  //       toast.error(t("error.fetchIndustries"));
  //     }
  //   };

  //   fetchIndustries();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const postData = { name, phoneNumber: number, countryCode };
      const response = await editVendor(postData);
      toast.success(t("profile.updatedSuccess"));
      localStorage.setItem("name", response?.data?.data?.name);
      window.location.reload();
      onHide();
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("profile.editTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t("profile.namePlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group className="d-flex" style={{ gap: "10px" }}>
              <PhoneInput
                enableSearch={true}
                country={"cy"}
                className="form-control p-0 bg-white"
                value={countryCode}
                onChange={(phone) => setCountryCode(phone)}
              />
              <Form.Control
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder={t("profile.phonePlaceholder")}
                required
              />
            </Form.Group>
            {/* <Form.Group controlId="formIndustry">
              <Form.Label>{t("profile.industryLabel")}</Form.Label>
              <Form.Control
                as="select"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                required
              >
                <option value="" disabled>
                  {t("profile.industryPlaceholder")}
                </option>
                {industries.map((ind) => (
                  <option key={ind.id} value={ind.id}>
                    {ind.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                size="sm"
                className="mr-2"
                type="submit"
              >
                {loader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("buttons.save")
                )}
              </Button>
              <Button variant="secondary" size="sm" onClick={onHide}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditVendorModal;
