import React, { useEffect, useState } from "react";
import AddSubVendorModal from "../modal/AddSubVendorModal";
import { Badge, Card, Col, Table } from "react-bootstrap";

import {
  getSubVendorApi,
  deleteEmployee,
} from "../../services/Store/StoreServices";
import SubVendorSkeleton from "../components/SkeletonPages/SubVendorSkeleton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../modal/ConfirmationModal";

export default function SubVendorManagement() {
  const [addSubVenderModal, setAddSubVenderModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [deleteStoreModal, setDeleteStoreModal] = useState(false);
  const [data, setData] = useState();
  const isVerify = localStorage.getItem("verifyStatus");

  const [list, setList] = useState([]);
  const { t } = useTranslation();

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getSubVendorApi(currentPage, limit)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  function onDelete(item) {
    setData(item);
    setDeleteStoreModal(true);
  }

  console.log(list);

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between p-3">
        <div>
          <h3 className="text-black font-w600 ">
            {t("employee_management.title")}
          </h3>
        </div>
        <div className="store-details-actions">
          <button
            className="btn btn-primary btn-sm "
            onClick={() => setAddSubVenderModal(true)}
            disabled={isVerify === "Accepted" ? false : true}
          >
            {t("employee_management.button_name")}
          </button>
        </div>
      </div>
      <div>
        <Col>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>{t("common.name")}</strong>
                    </th>
                    <th>
                      <strong>{t("employee_management.email_label")}</strong>
                    </th>
                    <th>
                      <strong>
                        {t("employee_management.created_at_label")}
                      </strong>
                    </th>
                    {/* <th>
                      <strong>{t("common.status")}</strong>
                    </th> */}
                    <th className="d-flex justify-content-center">
                      <strong>{t("common.action")}</strong>
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <SubVendorSkeleton count={8} />
                ) : (
                  <tbody>
                    {list?.vendors?.map((item, i) => (
                      <tr
                        key={i}
                        className={
                          item?.verifyStatus === "Pending"
                            ? "pending-row"
                            : item?.verifyStatus === "Accepted"
                            ? "success-row"
                            : item?.verifyStatus === "Rejected"
                            ? "danger-row"
                            : "default"
                        }
                      >
                        <td>{item?.name}</td>

                        <td>{item?.email}</td>
                        <td>{moment(item?.createdAt).format("ll")}</td>
                        {/* 
                        <td className="">
                          {!item?.isBlocked && (
                            <Badge variant="success light">
                              {t("employee_management.status_active_label")}
                            </Badge>
                          )}
                          {item?.isBlocked && (
                            <Badge variant="danger light">
                              {" "}
                              {t("employee_management.status_blocked_label")}
                            </Badge>
                          )}
                        </td> */}
                        <td className="d-flex justify-content-center">
                          <div
                            class="btn btn-danger  btn-xs sharp list-btn "
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => onDelete(item)}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              {list?.total === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  {t("common.empty_table")}
                </div>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Col>
      </div>

      {addSubVenderModal && (
        <AddSubVendorModal
          show={addSubVenderModal}
          onHide={() => setAddSubVenderModal(false)}
          refresh={getTableData}
        />
      )}
      {deleteStoreModal && (
        <ConfirmationModal
          show={deleteStoreModal}
          onHide={() => setDeleteStoreModal(false)}
          successMessage={`${t(
            "toast_messages.employee_delete_success_message"
          )}`}
          api={deleteEmployee}
          refresh={getTableData}
          content={`${t("common.delete_popup_message")}`}
          data={data}
        />
      )}
    </div>
  );
}
