import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Pagination from "../common/Pagination";
import defaultImage from "../../images/emptyImg.jpg";
import { getLoyaltyUsersApi } from "../../services/Store/StoreServices";
import { useDispatch } from "react-redux";
import moment from "moment";
import Filter from "../common/Filter";

export default function UserManagement(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [apiError, setApiError] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState(null);

  const limit = 10;

  function handleError(e) {
    e.target.src = defaultImage;
  }

  function getTableData() {
    setLoader(true);
    getLoyaltyUsersApi(currentPage, limit, search, filterType)
      .then((response) => {
        setUsers(response.data.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setApiError(error?.response?.data?.message);

        setLoader(false);
      });
  }
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  console.log(users);

  useEffect(() => {
    getTableData();
  }, [currentPage, search, filterType]);

  return (
    <div>
      <h3 className="text-black font-w600  p-3">Users List</h3>
      <Col>
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-group" style={{ width: "40%" }}>
            <input
              type="text"
              name="table_search"
              className="form-control"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Filter type={"filter"} setFilterType={setFilterType} />
        </div>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>
                    <th className="text-center">
                      <strong>Date of birth</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.users?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={`https://api.paybagapp.com/${item?.image}`}
                          height={50}
                          width={50}
                          style={{ borderRadius: "25px" }}
                          onError={handleError}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        +{item.countryCode} {item.phoneNumber}
                      </td>

                      <td className="text-center">
                        {moment(item.dateOfBirth).format("ll")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {users?.total === 0 && !loader && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {apiError && (
                <div className="justify-content-center d-flex my-5 ">
                  {apiError}
                </div>
              )}
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </Card.Body>
          </Card>
        )}
      </Col>

      {loader && <Spinner />}
    </div>
  );
}
