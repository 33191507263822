import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AddOffers = ({
  title,
  successMessage,
  show,
  onHide,
  api,
  refresh,
  programId,
  item,
}) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    tier: "",
    startDate: "",
    validDate: "",
    oncePerUser: "",
    pointPrice: "",
    pointNeeded: "",
    description: "",
  });
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("create_offer.unlimited_explanation")}
    </Tooltip>
  );

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatDate = (isoDate) => {
    return isoDate ? isoDate.slice(0, 10) : "";
  };

  const validate = () => {
    const newErrors = {};
    if (!formData?.title?.trim()) newErrors.title = t("required");
    if (!formData?.tier?.trim()) newErrors.tier = t("required");
    if (!formData.startDate) newErrors.startDate = t("required");
    if (!formData.validDate) newErrors.validDate = t("required");
    // if (!formData?.availableItems?.trim())
    //   newErrors.availableItems = t("required");
    if (!formData.pointPrice || formData.pointPrice < 1)
      newErrors.pointPrice = t("minimumOnePoint");
    if (!formData?.description?.trim()) newErrors.description = t("required");
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length !== 0) {
      setErrors(validationErrors);
      return;
    }
    setLoader(true);

    let dataToSend = { ...formData };

    if (title === "Edit Offer" && item?.id) {
      dataToSend.offerId = item.id;
    } else {
      dataToSend.programId = programId;
    }

    try {
      const response = await api(dataToSend);
      refresh();
      toast.success(successMessage);
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setFormData({
      title: item?.title,
      discountType: item?.discountType,
      tier: item?.tier,
      startDate: formatDate(item?.startDate),
      validDate: formatDate(item?.validDate),
      // availableItems: item?.availableItems,
      oncePerUser: item?.oncePerUser,
      pointPrice: item?.pointPrice,
      pointNeeded: item?.pointNeeded,
      description: item?.description,
    });
  }, [item]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* <div className="form-group">
            <label className="mb-1">{t("create_offer.offer_type")}</label>
            <select
              className="form-control"
              name="discountType"
              value={formData.discountType}
              onChange={handleChange}
              required
            >
              <option value="">Select..</option>
              <option value="Percentage discount">
                {t("create_offer.placeholders.percentage_discount")}
              </option>
              <option value="Fixed amount discount">
                {t("create_offer.placeholders.fixed_discount")}
              </option>
            </select>
            {errors.discountType && (
              <div className="text-danger fs-12">{errors.discountType}</div>
            )}
          </div> */}
          <Form.Group controlId="title">
            <Form.Label>{t("create_offer.offer_name")}</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder={t("create_offer.placeholders.offer_name")}
              value={formData.title}
              onChange={handleChange}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="tier">
            <Form.Label>{t("create_offer.tier")}</Form.Label>
            <Form.Control
              as="select"
              name="tier"
              value={formData.tier}
              onChange={handleChange}
              isInvalid={!!errors.tier}
            >
              <option value={""} disabled>
                {t("common.select")}
              </option>
              <option value="all">
                {t("create_edit_tier_form.label.all")}
              </option>
              <option value="silver">
                {t("create_edit_tier_form.label.silver")}
              </option>
              <option value="gold">
                {" "}
                {t("create_edit_tier_form.label.gold")}
              </option>

              <option value="platinum">
                {" "}
                {t("create_edit_tier_form.label.platinum")}
              </option>
              <option value="diamond">
                {" "}
                {t("create_edit_tier_form.label.diamond")}
              </option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.tier}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group controlId="availableItems">
            <Form.Label>{t("create_offer.itemsAvailable")}</Form.Label>
            <Form.Control
              type="text"
              name="availableItems"
              placeholder={t("create_offer.placeholders.enter_items")}
              value={formData.availableItems}
              onChange={handleChange}
              min={1}
              isInvalid={!!errors.availableItems}
            />
            <Form.Control.Feedback type="invalid">
              {errors.availableItems}
            </Form.Control.Feedback>
          </Form.Group> */}
          <Form.Label>{t("create_offer.onePerUser")}</Form.Label>
          <OverlayTrigger placement="right" overlay={renderTooltip}>
            <i
              className="fa-sharp fa-solid fa-circle-info p-1"
              style={{ fontSize: "20px", color: "#042e77" }}
            ></i>
            {/* <Button variant="link" className="p-0 font-w600"></Button> */}
          </OverlayTrigger>
          <Form.Group controlId="oncePerUser">
            <Form.Control
              type="number"
              name="oncePerUser"
              placeholder={t("create_offer.placeholders.enter_number")}
              label={t("create_offer.oncePerUser")}
              min={1}
              value={formData.oncePerUser}
              // value={
              //   formData.oncePerUser > 1000000
              //     ? t("create_offer.unlimited")
              //     : formData.oncePerUser
              // }
              onChange={handleChange}
            />
          </Form.Group>
          <div className="form-group">
            <label className="m-0">{t("create_offer.pointsNeeded")}</label>
            <select
              className="form-control"
              name="pointNeeded"
              value={formData.pointNeeded}
              onChange={handleChange}
              required
            >
              <option value="">{t("common.select")}</option>
              <option value="Debit">
                {t("create_offer.placeholders.debit")}
              </option>
              <option value="Credit">
                {t("create_offer.placeholders.credit")}
              </option>
            </select>
          </div>
          <Form.Group controlId="pointPrice">
            <Form.Label>{t("create_offer.points_price")}</Form.Label>
            <Form.Control
              type="number"
              name="pointPrice"
              placeholder={t("create_offer.placeholders.enter_points")}
              value={formData.pointPrice}
              onChange={handleChange}
              isInvalid={!!errors.pointPrice}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pointPrice}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="startDate">
            <Form.Label>{t("create_offer.timeStarts")}</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              isInvalid={!!errors.startDate}
              min={getTodayDate()}
            />
            <Form.Control.Feedback type="invalid">
              {errors.startDate}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validDate">
            <Form.Label>{t("create_offer.timeEnd")}</Form.Label>
            <Form.Control
              type="date"
              name="validDate"
              value={formData.validDate}
              onChange={handleChange}
              isInvalid={!!errors.validDate}
              min={getTodayDate()}
            />
            <Form.Control.Feedback type="invalid">
              {errors.validDate}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>{t("create_offer.description")}</Form.Label>
            <Form.Control
              style={{ minHeight: "100px" }}
              as="textarea"
              name="description"
              placeholder={t("create_offer.placeholders.description")}
              value={formData.description}
              onChange={handleChange}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" className="w-100 btn-sm">
            {" "}
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              `${t("create_offer.submit")}`
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddOffers;
