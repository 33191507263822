import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import Pagination from "../common/Pagination";
import { getStore } from "../../services/Store/StoreServices";
import AddStore from "../modal/AddStore";
import defaultImage from "../../images/emptyImg.jpg";
import {
  addStore,
  editStore,
  deleteStore,
} from "../../services/Store/StoreServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import UserTableSkeleton from "../components/SkeletonPages/UserTableSkeleton";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../modal/ConfirmationModal";
import { storeIdAction } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import moment from "moment";

export default function StoreManagement(props) {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [addStoreModal, setAddStoreModal] = useState(false);
  const [editStoreModal, setEditStoreModal] = useState(false);
  const [deleteStoreModal, setDeleteStoreModal] = useState(false);
  const [data, setData] = useState();
  const { t } = useTranslation();
  const isVerify = localStorage.getItem("verifyStatus");
  const dispatch = useDispatch();

  const limit = 10;

  function handleError(e) {
    e.target.src = defaultImage;
  }

  function getTableData() {
    setLoader(true);
    getStore(currentPage, limit)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.countUser;
        dispatch(storeIdAction(response?.data?.data[0]?.id));
        localStorage.setItem("storeId", response?.data?.data[0]?.id);

        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  console.log(list[0]?.id);
  async function handleDelete(id) {
    setLoader(true);
    try {
      const res = await deleteStore(id);
      getTableData();
      toast.success(`${t("toast_messages.delete_store_success_message")}`);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  function onSendStoreItem(item) {
    props.history.push({
      pathname: "./store-details",
      state: item,
    });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center page-title">
        <div className="">
          <h3 className="text-black font-w600  m-0">
            {t("store_management.title")}
          </h3>
        </div>
        {!list?.length && (
          <button
            className=" btn btn-primary btn-sm"
            onClick={() => setAddStoreModal(true)}
            disabled={isVerify === "Accepted" ? false : true}
          >
            {t("common.add_store")}
          </button>
        )}
      </div>
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong> {t("common.image")}</strong>
                  </th>
                  <th>
                    <strong> {t("common.name")}</strong>
                  </th>
                  <th>
                    <strong> {t("common.open_time")}</strong>
                  </th>
                  <th>
                    <strong> {t("common.close_time")}</strong>
                  </th>

                  <th>
                    <strong> {t("common.phone")}</strong>
                  </th>

                  <th>
                    <strong> {t("common.action")}</strong>
                  </th>
                </tr>
              </thead>
              {loader ? (
                <UserTableSkeleton count={8} />
              ) : (
                <tbody>
                  {list?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={`https://api.paybagapp.com/${item?.image}`}
                          height={50}
                          width={50}
                          onClick={() => onSendStoreItem(item?.id)}
                          style={{ borderRadius: "25px" }}
                          onError={handleError}
                        />
                      </td>
                      <td onClick={() => onSendStoreItem(item?.id)}>
                        {item?.storeName}
                      </td>
                      <td onClick={() => onSendStoreItem(item?.id)}>
                        {moment(item?.openTime, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td onClick={() => onSendStoreItem(item?.id)}>
                        {moment(item?.closeTime, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td onClick={() => onSendStoreItem(item?.id)}>
                        {item?.contactNumber}
                      </td>

                      <td>
                        <div class="d-flex" style={{ gap: "10px" }}>
                          <div
                            class="btn btn-primary shadow btn-xs sharp list-btn"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditStoreModal(true);
                              setData(item);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />{" "}
                          </div>
                          <div
                            class="btn btn-danger  btn-xs sharp list-btn"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => {
                              setDeleteStoreModal(true);
                              setData(item);
                            }}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {list?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                {t("common.empty_table")}
              </div>
            ) : (
              ""
            )}
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </Card.Body>
        </Card>
      </Col>
      {addStoreModal && (
        <AddStore
          title={t("common.add_store")}
          successMessage={t("toast_messages.aad_store_success_message")}
          show={addStoreModal}
          onHide={() => setAddStoreModal(false)}
          list={getTableData}
          api={addStore}
          buttonTitle={t("common.add_store")}
        />
      )}
      {editStoreModal && data && (
        <AddStore
          title={t("common.update")}
          successMessage={t("toast_messages.edit_store_success_message")}
          show={editStoreModal}
          onHide={() => setEditStoreModal(false)}
          list={getTableData}
          api={editStore}
          item={data}
          buttonTitle={t("common.update")}
        />
      )}
      {deleteStoreModal && (
        <ConfirmationModal
          show={deleteStoreModal}
          onHide={() => setDeleteStoreModal(false)}
          successMessage={`${t("toast_messages.delete_store_success_message")}`}
          api={deleteStore}
          refresh={getTableData}
          content={`${t("common.delete_popup_message")}`}
          data={data}
        />
      )}
    </div>
  );
}
